import React from 'react';
import PropTypes from 'prop-types';

import isNotEmpty from '../../../lib/is-not-empty-structured-text';
import Section from '../../../component-elements/content-section';
import CmsTextComponent from '../../../content-elements/text';
import CmsImageComponent from '../../../content-elements/image';

const CmsImagesBrandText = (props) => {
  const {
    title,
    images,
    headline,
    background,
    intro,
    text,
  } = props;

  return (
    <Section mobileSwitch background={background}>
      <div className="content-col content-col-first col-lg-7">
        <div className="image-container">
          {
            images.map((image) => (
              <figure key={image.createdAt} className="figure landscape">
                <CmsImageComponent image={image} />
              </figure>
            ))
          }
        </div>
      </div>
      <div className="content-col content-col-last col-lg-8 offset-lg-1 ">
        {
          (isNotEmpty(headline) || title) && (
            <header className="section-header">
              {isNotEmpty(headline) && <CmsTextComponent content={headline} />}
              {title && <span className="section-subtitle">{title}</span>}
            </header>
          )
        }
        {
          intro && (
            <div className="lead">
              <CmsTextComponent content={intro} />
            </div>
          )
        }
        {text && (
          <CmsTextComponent content={text} />
        )}
      </div>
    </Section>
  );
};

CmsImagesBrandText.defaultProps = {
  title: '',
  images: undefined,
};

CmsImagesBrandText.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.any),
  headline: PropTypes.objectOf(PropTypes.object).isRequired,
  intro: PropTypes.objectOf(PropTypes.any).isRequired,
  text: PropTypes.objectOf(PropTypes.any).isRequired,
  background: PropTypes.string.isRequired,
};

export default CmsImagesBrandText;
