import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ContentHeader from '../../component-elements/contentHeaderBackground';
import t from '../../lib/translate';

const HeaderBrand = (props) => {
  const {
    name,
    headerImage,
    headerImageMobile,
    logo,
    collectionsUrlPath,
    productsUrlPath,
    hasCollections,
  } = props;

  return (
    <ContentHeader headerImage={headerImage} headerImageMobile={headerImageMobile} logo={logo}>
      <h1>{name}</h1>
      { hasCollections && (
        <Link to={collectionsUrlPath} className="btn btn-primary">
          {t`brand_header_collectionlist_button_label`}
        </Link>
      )}
      { !hasCollections && (
        <Link
          to={productsUrlPath}
          className="btn btn-outline"
        >
          {t`brand_products_button_label`}
        </Link>
      )}
    </ContentHeader>
  );
};

HeaderBrand.defaultProps = {
  hasCollections: false,
  headerImage: undefined,
  headerImageMobile: undefined,
  logo: undefined,
};

HeaderBrand.propTypes = {
  name: PropTypes.string.isRequired,
  collectionsUrlPath: PropTypes.string.isRequired,
  productsUrlPath: PropTypes.string.isRequired,
  hasCollections: PropTypes.bool,
  logo: PropTypes.objectOf(PropTypes.any),
  headerImage: PropTypes.objectOf(PropTypes.any),
  headerImageMobile: PropTypes.objectOf(PropTypes.any),
};

export default HeaderBrand;
