import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import t from '../../../lib/translate';
import isNotEmpty from '../../../lib/is-not-empty-structured-text';

import Section from '../../../component-elements/content-section';
import CmsTextComponent from '../../../content-elements/text';
import CmsImageComponent from '../../../content-elements/image';
import ProductItem from '../../../components/shop-product/item';

import 'swiper/swiper.scss';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const CmsProductCollection = (props) => {
  const {
    lang,
    title,
    collection,
    headline,
    background,
    text,
    imageLeft,
    productDetailPath,
    magentoProducts,
  } = props;

  return (
    <>
      <Section mobileSwitch={imageLeft} background={background}>
        {
          imageLeft === false ? (
            <>
              <div className="content-col content-col-first col-lg-5 content-highlight-col">
                {
                  (isNotEmpty(headline) || title) && (
                    <header className="section-header">
                      {isNotEmpty(headline) && <CmsTextComponent content={headline} />}
                      {title && <span className="section-subtitle">{title}</span>}
                    </header>
                  )
                }
                <CmsTextComponent content={text} />
                <Link
                  to={collection.fullUrlPath}
                  className="btn btn-primary"
                >
                  {t('brand_ce_collectionteaser_collection_button_label', collection.name)}
                </Link>
              </div>
              <div className="content-col content-col-last col-lg-9 offset-lg-2 mt-5 mt-lg-0">
                <div className="image-container">
                  <figure className="figure landscape">
                    {
                      collection.teaser ? (
                        <CmsImageComponent image={collection.teaser} renderAs="div" />
                      ) : (
                        <StaticImage src="../../../images/default.png" width={944} alt={collection.name} />
                      )
                    }
                  </figure>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="content-col content-col-first col-lg-9 mt-5 mt-lg-0">
                <div className="image-container">
                  <figure className="figure landscape">
                    {
                      collection.teaser ? (
                        <CmsImageComponent image={collection.teaser} renderAs="div" />
                      ) : (
                        <StaticImage src="../../../images/default.png" width={944} alt={collection.name} />
                      )
                    }
                  </figure>
                </div>
              </div>
              <div className="content-col content-col-first col-lg-5 offset-lg-1 content-highlight-col">
                {
                  (isNotEmpty(headline) || title) && (
                    <header className="section-header">
                      {isNotEmpty(headline) && <CmsTextComponent content={headline} />}
                      {title && <span className="section-subtitle">{title}</span>}
                    </header>
                  )
                }
                <CmsTextComponent content={text} />
                <Link
                  to={collection.fullUrlPath}
                  className="btn btn-primary"
                >
                  {t('brand_ce_collectionteaser_collection_button_label', collection.name)}
                </Link>
              </div>
            </>
          )
        }
      </Section>
      {
        (magentoProducts.length > 0) && (
          <Section noGrid background="Weiß">
            <div className={`slider product-slider has-items-${magentoProducts.length}`}>
              <span className="icon icon-left icon-gold swiper-button swiper-button-prev prev">
                <span className="sr-only">{t`pager_prev_label`}</span>
              </span>
              <span className="icon icon-right icon-gold swiper-button swiper-button-next next">
                <span className="sr-only">{t`pager_next_label`}</span>
              </span>
              <Swiper
                slidesPerView={magentoProducts.length >= 3 ? 3 : 2}
                spaceBetween={30}
                slidesPerGroup={1}
                loopedSlides={50}
                loop
                watchOverflow
                navigation={{
                  prevEl: '.prev',
                  nextEl: '.next',
                }}
                breakpoints={{
                  1275: {
                    slidesPerView: magentoProducts.length >= 3 ? 3 : magentoProducts.length,
                  },
                  767: {
                    slidesPerView: magentoProducts.length >= 2 ? 2 : magentoProducts.length,
                  },
                  320: {
                    slidesPerView: 1,
                  },
                }}
                speed={1000}
                className={`has-items-${magentoProducts.length}`}
              >
                {magentoProducts.map((product) => (
                  <SwiperSlide
                    key={product.sku}
                    className="swiper-slide"
                  >
                    <ProductItem
                      lang={lang}
                      urlPrefix={productDetailPath}
                      imageSize={520}
                      {...product}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Section>
        )
      }
    </>
  );
};

CmsProductCollection.defaultProps = {
  title: '',
};

CmsProductCollection.propTypes = {
  imageLeft: PropTypes.bool.isRequired,
  headline: PropTypes.objectOf(PropTypes.object).isRequired,
  text: PropTypes.objectOf(PropTypes.any).isRequired,
  background: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  magentoProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  productDetailPath: PropTypes.string.isRequired,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fullUrlPath: PropTypes.string.isRequired,
    teaser: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  title: PropTypes.string,
};

export default CmsProductCollection;
